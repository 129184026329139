let Omise;

export function createCardToken(
  name,
  number,
  expiration_month,
  expiration_year,
  security_code,
) {
  if (typeof window !== 'undefined' && window.Omise) {
    Omise = window.Omise;
    Omise.setPublicKey(process.env.GATSBY_OMISE_KEY);
  }

  return new Promise((resolve, reject) => {
    Omise.createToken(
      'card',
      { name, number, expiration_month, expiration_year, security_code },
      (statusCode, response) => {
        if (statusCode === 200) {
          resolve(response);
        } else {
          reject(response.message);
        }
      },
    );
  });
}

export function createPromtpay(amount) {
  if (typeof window !== 'undefined' && window.Omise) {
    Omise = window.Omise;
    Omise.setPublicKey(process.env.GATSBY_OMISE_KEY);
  }

  return new Promise((resolve, reject) => {
    Omise.createSource(
      'promptpay',
      {
        amount: amount,
        currency: 'THB',
      },
      (statusCode, response) => {
        if (statusCode === 200) {
          resolve(response);
        } else {
          reject(response.message);
        }
      },
    );
  });
}
