import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createForm } from 'rc-form';
import { makeStyles } from '@material-ui/core/styles';
import { createLoadingSelector } from '../reducers/api';
import { postCreditCard } from '../actions/creditCards';
import Payment from 'payment';
import Cards from 'react-credit-cards';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import Button from '@material-ui/core/Button';
import visa from '../assets/creditcards/visa.png';
import mastercard from '../assets/creditcards/mastercard.png';
import jcb from '../assets/creditcards/jcb.png';
import omise from '../assets/creditcards/omise.svg';

const useStyles = makeStyles(theme => ({
  omise: {
    top: 4,
    position: 'relative',
  },
}));

const AddCard = ({ form }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector(['credit/post']));

  const [focused, setFocused] = React.useState('');
  const [error, setError] = React.useState({});
  const [postError, setPostError] = React.useState();
  const numberRef = React.useRef();
  const validRef = React.useRef();
  const cvcRef = React.useRef();
  const anchor = React.useRef();

  React.useEffect(() => {
    Payment.formatCardNumber(numberRef.current);
    Payment.formatCardExpiry(validRef.current);
    Payment.formatCardCVC(cvcRef.current);
  }, []);

  const handleInputFocus = name => () => {
    scrollToAnchor();
    setFocused(name);
  };

  const validate = (fn, error) => (rule, value, callback) => {
    if (!fn(value)) {
      callback(error);
    }
    callback();
  };

  const scrollToAnchor = () => {
    anchor.current.scrollIntoView(true);
    window.scrollBy(0, -15);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setError({});
    form.validateFields((err, values) => {
      if (!err) {
        const type = Payment.fns.cardType(values.number);
        const number = values.number.trim();
        const expiry = values.expiry.trim().split('/');
        fetch(values.name, number, expiry[0], expiry[1], values.cvc, type);
      } else {
        setError(err);
      }
    });
  };

  const fetch = async (name, number, expiry1, expiry2, cvc, type) => {
    window.logEvent('add_payment_info', {
      currency: 'THB',
      payment_type: 'credit card',
    });
    try {
      await dispatch(postCreditCard(name, number, expiry1, expiry2, cvc, type));
      window.history.back();
    } catch (e) {
      setPostError(e);
    }
  };

  return (
    <Container maxWidth='sm'>
      <Box my={2}>
        {postError && (
          <Box mb={2}>
            <SnackbarContent
              message={
                <small>
                  <WarningIcon />
                  We were unable to authorize the payment to your credit card.
                  Please confirm that all the details are correct. You may also
                  contact your bank to confirm that they are not blocking the
                  payment or use an alternate payment method.
                </small>
              }
            />
          </Box>
        )}
        <Cards
          acceptedCards={['visa', 'mastercard', 'discover', 'amex']}
          number={
            (form.getFieldValue('number') &&
              form.getFieldValue('number').trim()) ||
            ''
          }
          name={form.getFieldValue('name') || ''}
          expiry={
            (form.getFieldValue('expiry') &&
              form
                .getFieldValue('expiry')
                .trim()
                .replace('/', '')) ||
            ''
          }
          cvc={form.getFieldValue('cvc') || ''}
          focused={focused}
        />
        <div ref={anchor}></div>
        <Box mt={2}>
          <Grid container spacing={1} justify='flex-end'>
            <Grid item>
              <small>บัตรที่รองรับ</small>
            </Grid>
            <Grid item>
              <img src={visa} width='30' alt='visa' />
            </Grid>
            <Grid item>
              <img src={mastercard} width='30' alt='mastercard' />
            </Grid>
            <Grid item>
              <img src={jcb} width='30' alt='jcb' />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {form.getFieldDecorator('number', {
              rules: [
                {
                  validator: validate(
                    Payment.fns.validateCardNumber,
                    'เลขไม่ถูกต้อง',
                  ),
                },
              ],
            })(
              <TextField
                fullWidth
                error={!!error.number}
                ref={numberRef}
                id='outlined-dense'
                label='เลขที่บัตร'
                margin='dense'
                variant='outlined'
                onFocus={handleInputFocus('number')}
              />,
            )}
          </Grid>
          <Grid item xs={12}>
            {form.getFieldDecorator('name', {
              rules: [{ required: true, message: 'กรุณาใส่' }],
            })(
              <TextField
                fullWidth
                error={!!error.number}
                id='outlined-dense'
                label='ชื่อบนบัตร'
                margin='dense'
                variant='outlined'
                onFocus={handleInputFocus('name')}
              />,
            )}
          </Grid>
          <Grid item xs={6}>
            {form.getFieldDecorator('expiry', {
              rules: [
                {
                  validator: validate(
                    Payment.fns.validateCardExpiry,
                    'วันที่ไม่ถูกต้อง',
                  ),
                },
              ],
            })(
              <TextField
                ref={validRef}
                error={!!error.expiry}
                id='outlined-dense'
                label='วันที่หมดอายุ'
                margin='dense'
                variant='outlined'
                onFocus={handleInputFocus('expiry')}
              />,
            )}
          </Grid>
          <Grid item xs={6}>
            {form.getFieldDecorator('cvc', {
              rules: [
                {
                  validator: validate(
                    Payment.fns.validateCardCVC,
                    'cvc ไม่ถูกต้อง',
                  ),
                },
              ],
            })(
              <TextField
                ref={cvcRef}
                error={!!error.cvc}
                id='outlined-dense'
                label='CVC'
                margin='dense'
                variant='outlined'
                onFocus={handleInputFocus('cvc')}
              />,
            )}
          </Grid>
        </Grid>
        <Box my={2}>
          <Grid container justify='center'>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={handleSubmit}
              disabled={loading}
            >
              เพิ่มบัตร
            </Button>
          </Grid>
        </Box>
        <Grid container justify='center'>
          <small>
            Secured by &nbsp;
            <img className={classes.omise} src={omise} width='55' alt='omise' />
          </small>
        </Grid>
      </Box>
    </Container>
  );
};

export default createForm()(AddCard);
